html {
  min-height: 150%;
  background-size: cover;
  padding-bottom: 5%;
  overflow-x: hidden;
}

#movie-input {
  outline: none;
  margin: 0 10%;
  width: 80%;
  padding: 14px;
}

.App-logo {
  display: grid;
  height: 13vh;
  margin-top: auto;
  pointer-events: none;
}

.App-header {
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2vh;
}

h6 {
  color: #f1b92b;
  text-align: center;
  margin: 0 7% 10px 7%;
}

.results-list {
  display: flex;
  color: #fefefe;
  font-size: 1.3rem;
  margin: 15px 5% 3% 24%;
}

.nominations-list {
  display: flex;
  color: #fefefe;
  font-size: 1.3rem;
  margin: 15px 24% 3% 0%;
}

.movie-card {
  margin: 0.2% 0% 0.1% 24%;
}

.nomination-card {
  margin: 0.2% 24% 0.2% 0%;
}

.movie {
  display: flex;
  justify-content: center;
  height: 5rem;
  color: #fefefe;
  margin-bottom: 0.3rem;
}

.movie-thumbnail {
  height: 100%;
  min-width: 55px;
  max-width: 55px;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.movie-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  background: #302e41;
  overflow: hidden;
}

.movie-info.nominee {
  background: #6c6a85;
}

.movie-info:hover {
  background: rgba(182, 21, 64, 0.4);

}

.movie-title {
  font-size: 85%;
  font-weight: bolder;
}

.movie-year {
  font-size: 70%;
}

.pagination-btn{
  display: flex;
  justify-content: center;
  margin: 15px 8% 15px 8%;
  gap: 1%;
}

.fade {
  width: 76%;
  margin: 2% 12%;
}

.more-details::before {
  content: "More Details";
}

@media only screen and (max-width: 991px) and (min-width: 600px){
  .App-logo {
    height: 12vh;
    margin-top: auto;
  }

  .results-list {
    margin: 2% 4% 3% 8%;
  }

  .nominations-list {
    margin: 4% 4% 3% 8%;
  }
  
  .movie-card {
    margin: 0.2% 7% 0% 8%;
  }

  .nomination-card {
    margin: 0.2% 7% 0% 8%;
  }
}

@media only screen and (max-width: 600px) {
  #movie-input {
    margin: 0 3%;
    width: 95%;
  }
  
  .results-list, .nominations-list {
    font-size: 110%;
    margin: 3.5% 4% 3% 8%;
  }

  .movie-title, .movie-year{
    font-size: 70%;
  }

  .movie-thumbnail {
    height: 100%;
    min-width: 50px;
  }

  .movie-card {
    margin: 0.2% 6% 0% 8%;
  }

  .nomination-card {
    margin: 0.2% 6% 0% 8%;
  }  

  .more-details::before {
    font-family: FontAwesome;
    font-size: large;
    content: "\f05a";
  }  
}
